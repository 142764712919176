import React, { useRef, useEffect, useState } from "react";
import ListItem from "./ListItem";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import PropTypes from "prop-types";
import * as ListStyling from "./List.module.scss";
import { graphql } from "gatsby";

const List = ({ block }) => {

  const items = block.items;
  const listRef = useRef();
  const [parentHasClass, setParentHasClass] = useState(true);

  useEffect(() => {
    if (listRef.current.parentElement.nodeName === "MAIN") {
      setParentHasClass(true);
    } else {
      setParentHasClass(false);
    }
  }, []);

  return (
    <Section
      ref={listRef}
      className={parentHasClass ? "" : ListStyling.listWrapper}
    >
      <ul className={ListStyling.listModule}>
        {items.map((item) => {
          return (
            <ListItem
              key={item.id}
              item={item}
            />
          );
        })}
      </ul>
    </Section>
  );
};

export default List;

List.propTypes = {
  block: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.shape({
          value: PropTypes.object
        }),
        addAnImage: PropTypes.bool,
        icon: PropTypes.array,
        visibleFor: PropTypes.string.isRequired,
        image: PropTypes.shape({
          alt: PropTypes.string.isRequired,
          gatsbyImageData: PropTypes.object.isRequired,
        }),
      })
    ).isRequired,
  }),
};

export const query = graphql`
  fragment List on DatoCmsList {
    model {
      apiKey
    }
    id: originalId
    items {
      ...ListItem
    }
  }
`;

import React from "react";
import * as widont from "widont";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as ListStyling from "./List.module.scss";
import PropTypes from "prop-types";
import { Icon } from "@soundtrackyourbrand/ui/src/Icon";
import Personalization from "../../Personalization/Personalization";
import { graphql } from "gatsby";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";

const ListItem = ({ item }) => {
  const { image, subtitle, visibleFor, addAnImage } = item;
  const title = widont(item.title);
  const icon = item.icon ? item.icon[0] : null;

  const itemImage = getImage(image);

  return (
    <Personalization visibleFor={visibleFor}>
      <li className={`row sm-12 mb-6 ${ListStyling.listItem}`}>
        <div className="col c-auto">
          {addAnImage
            ? image && (
                <GatsbyImage
                  image={itemImage}
                  alt={image.alt}
                  className={ListStyling.image}
                />
              )
            : icon && (
                <Icon
                  name={icon.iconType}
                  style={{ fill: `${icon.iconColor.hex}` }}
                  className={ListStyling.image}
                ></Icon>
              )}
        </div>
        <div className="col c-flex">
          <p className="text5 mb-4 hide-to-s">{title}</p>
          <p className="text4 mb-0 hide-from-s">{title}</p>
          {subtitle.value && (
            <StructuredTextRenderer
              data={subtitle}
              isParagraphClassName="text4 mb-0 subtle"
            />
          )}
        </div>
      </li>
    </Personalization>
  );
};

export default ListItem;

ListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.shape({
      value: PropTypes.object,
    }),
    addAnImage: PropTypes.bool,
    icon: PropTypes.array,
    visibleFor: PropTypes.string.isRequired,
    image: PropTypes.shape({
      alt: PropTypes.string.isRequired,
      gatsbyImageData: PropTypes.object.isRequired,
    }),
  }).isRequired,
};

export const query = graphql`
  fragment ListItem on DatoCmsListitem {
    id: originalId
    title
    subtitle {
      value
    }
    visibleFor
    icon {
      id: originalId
      iconType
      iconColor {
        hex
      }
    }
    addAnImage
    image {
      gatsbyImageData
      alt
    }
  }
`;
